import React, {useState,useEffect} from "react";
import Image from "react-image-webp";
import "./Afiliados.scss";
import { Row, Col, Button, Container, Carousel } from "react-bootstrap";
import afiliado from "./Afiliados.json";
import Alertas from "../../components/Alertas";


export default function Afiliados() {
  //archivo json
  const [items,setItems]=useState([]);

  useEffect(()=>{
    setItems(afiliado);
  },[]);
 
  const [text,setText] = useState()
  const [updated,setUpdated] = useState()

  //Metodo filtrado--------------------------
  let results = []
  if(!text || text == 0){
    results = [];
  }else{
    results = items.filter((datos)=> datos.DNI == (text));
      
  } 

  
//---------------------------------------------------

  const textOnChange = (event) => {
    setText(event.target.value)
  }

  const buttonOnClick = () => {
    setUpdated(text);  
    if(results == 0){
      alert("NO ES AFILIADO");
      
    }
   
  }  
  
  return (
    <>
      <Container>
      <Row className="text-left mt-5">
              <h2 className="titulo">
                  Buscar Afiliado por DNI
              </h2>              
          </Row>
          <div className="contenedor">
            <input className="entrada" type="number" value={text} onChange={textOnChange} placeholder="DNI del Afiliado"></input>
            <button className="miBoton" onClick={buttonOnClick}>Buscar</button>           

            {results.map((item)=>(
              <div>
                  <p className="resultado">{item.NOMBRE}</p>
                  <p className="resultado">{item.APELLIDO}</p>                  
              </div>
             ))}   

          </div>
         

        <Alertas />
      </Container>
    </>
  );
}

import React from "react";
import Image from "react-image-webp";
import { Row, Col, Carousel, Card, Button, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";


import "./QuienesSomos.scss";

import Alertas from "../../components/Alertas";

export default function QuienesSomos() {
  return (
    
    <>
      <Container>
        <Carousel>
          <Carousel.Item>
            <Image webp={require("../../assets/Emer-bannerweb-1.png")} />
          </Carousel.Item>
          <Carousel.Item>
            <Image webp={require("../../assets/Emer-bannerweb-2.png")} />
          </Carousel.Item>
          <Carousel.Item>
            <Image webp={require("../../assets/Emer-bannerweb-3.png")} />
          </Carousel.Item>
          <Carousel.Item>
            <Image webp={require("../../assets/Emer-bannerweb-4.png")} />
          </Carousel.Item>
          <Carousel.Item>
            <Image webp={require("../../assets/Emer-bannerweb-5.png")} />
          </Carousel.Item>
          <Carousel.Item>
            <Image webp={require("../../assets/Emer-bannerweb-6.png")} />
          </Carousel.Item>
          <Carousel.Item>
            <Image webp={require("../../assets/Emer-bannerweb-7.png")} />
          </Carousel.Item>
        </Carousel>
        <Row className="mt-3 mb-3">
          <Col className="d-flex justify-content-center">
            <Card className="mr-3 shadow p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title className="text-left">Turnos</Card.Title>
                <Card.Text className="text-left">
                  Sacá tu turno de forma anticipada, y recibí el mejor servicio
                  en Nuestro Centro de Especialidades Médicas...
                </Card.Text>
                <div className="text-right">
                  <NavLink
                    to="/Contacto">Leer más
                  </NavLink>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="d-flex justify-content-center">
            <Card className="shadow p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title className="text-left">Servicios</Card.Title>
                <Card.Text className="text-left">
                  Conocé todos nuestros servicios y recibí la mejor cobertura...
                </Card.Text>
                <div className="text-right">
                  <NavLink
              to="/Servicios"
            >Leer más</NavLink>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Alertas />
      </Container>
    </>
    
  );
}
